<template>
  <div
    v-if="$can('read', 'Media')"
    style="height: inherit"
  >

    <image-light-box
      ref="refImageLightBox"
      :show-light-box="false"
      :show-caption="true"
      :show-thumbs="false"
      :disable-zoom="false"
      :media="mediaLightBox"
    />

    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Header -->
      <section id="media-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="media-header-items">
              <div class="result-toggler">
                <div class="search-results">
                  {{ `${mediaTotal} ${$t('Results')}` }}
                </div>
              </div>
              <div class="view-options d-flex">

                <!-- Sort Button -->
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sortBy.text"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="sortOption in sortByOptions"
                    :key="sortOption.value"
                    @click="sortBy=sortOption"
                  >
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sortDesc.text"
                  style="margin-left: 0.4rem;"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="sortOption in sortDescOptions"
                    :key="sortOption.value"
                    @click="sortDesc=sortOption"
                  >
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                  <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    <feather-icon
                      :icon="option.icon"
                      size="18"
                    />
                  </b-form-radio>
                </b-form-radio-group>

                <b-button
                  class="ml-1"
                  size="sm"
                  variant="outline-primary"
                  @click="addMediaForm"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="media-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="filters.q"
                :placeholder="$t('Search')"
                class="search-product"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Media List -->
      <section :class="itemView">
        <b-card
          v-for="media in mediaList"
          :key="media.id"
          class="media-card"
          no-body
        >
          <div
            v-if="media.type === 'image'"
            class="item-img text-center"
          >
            <b-link
              @click="showPopupImage(media)"
            >
              <b-img
                v-if="media.media_url"
                :alt="`${media.name}-${media.id}`"
                fluid
                class="card-img-top"
                :src="media.media_url"
              />
            </b-link>
          </div>
          <div
            v-if="media.type === 'video'"
            class="item-img text-center"
          >
            <vue-viaudio
              :kind="'video'"
              :controls="true"
              :src="[media.media_url]"
              playinline
              :style="{width: '100%'}"
            />
          </div>

          <!-- Detail  -->
          <b-card-body>
            <div class="item-wrapper">
              <div>
                <h6 class="item-price">
                  {{ media.name }}
                </h6>
              </div>
            </div>
            <b-card-text class="item-description">
              <div>
                <span>{{ $t('Update Date') }}</span>
                <feather-icon
                  icon="CalendarIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >{{ media.update_date_show }}
                </span>
              </div>
              <div>
                <span>{{ $t('Update Time') }}</span>
                <feather-icon
                  icon="ClockIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >{{ media.update_time_show }}
                </span>
              </div>
            </b-card-text>
            <b-card-text class="item-description">
              <div>
                <span>{{ $t('Media Type') }}</span>
                <feather-icon
                  icon="CameraIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >
                  {{ `${$t(textFirstUpper(media.type))}` }}
                </span>
              </div>
              <div>
                <span>{{ $t('File Size') }}</span>
                <feather-icon
                  icon="SaveIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >
                  {{ `${media.size} ${$t('Byte')}` }}
                </span>
              </div>
              <div>
                <span>{{ $t('Width x Height') }}</span>
                <feather-icon
                  icon="MaximizeIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >
                  {{ `${media.width} x ${media.height}` }}
                </span>
              </div>
              <div
                v-if="media.type === 'video'"
              >
                <span>{{ $t('Duration') }}</span>
                <feather-icon
                  icon="VideoIcon"
                  class="text-primary"
                  style="margin-left: 0.4rem; margin-right: 0.4rem;"
                />
                <span
                  class="text-primary"
                >
                  {{ `${media.duration} ${$t('Second')}` }}
                </span>
              </div>
            </b-card-text>
            <b-card-text
              v-if="media.type === 'image'"
              class="item-description"
            >
              <b-button
                variant="outline-secondary"
                size="sm"
                class="w-100"
                @click="copyImageURL(media)"
              >
                <feather-icon
                  icon="ImageIcon"
                  class="mr-50"
                />
                <span>{{ $t('Copy Image URL') }}</span>
              </b-button>
            </b-card-text>
            <b-card-text
              v-if="media.type === 'image'"
              class="item-description"
            >
              <b-button
                variant="outline-success"
                size="sm"
                class="w-100"
                @click="copyImagemapURL(media)"
              >
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-50"
                />
                <span>{{ $t('Copy Imagemap URL') }}</span>
              </b-button>
            </b-card-text>
            <b-card-text
              v-if="media.type === 'video'"
              class="item-description"
            >
              <b-button
                variant="outline-secondary"
                size="sm"
                class="w-100"
                @click="copyVideoURL(media)"
              >
                <feather-icon
                  icon="VideoIcon"
                  class="mr-50"
                />
                <span>{{ $t('Copy Video URL') }}</span>
              </b-button>
            </b-card-text>
          </b-card-body>

          <!-- Actions -->
          <div class="item-options text-center">
            <b-button
              variant="light"
              tag="a"
              class="btn-wishlist"
              @click="deleteMediaForm(media)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50 text-danger"
              />
              <span class="text-danger">{{ $t('Delete') }}</span>
            </b-button>
            <b-button
              variant="info"
              tag="a"
              class="btn-cart"
              @click="editMediaForm(media)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span>{{ $t('Edit') }}</span>
            </b-button>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="mediaTotal"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <media-form
        v-model="shallShowMediaFormModal"
        :media-data="mediaData"
        :type-media-form="typeMediaForm"
        @media-form-update="updateMediaForm"
        @discard-media-form="discardMediaForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageLightBox from 'vue-image-lightbox'
import VueViaudio from '@dongido/vue-viaudio'
import mediaStoreModule from './mediaStoreModule'
import MediaForm from './MediaForm.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    ImageLightBox,
    MediaForm,
    VueViaudio,

    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      showOverlay: false,
      shallShowMediaFormModal: false,
      typeMediaForm: '',
      defaultData: {},
      mediaData: {},
      mediaList: [],
      filters: {
        q: '',
        page: 1,
        perPage: 5,
      },
      sortBy: { text: this.$i18n.t('Date'), value: 'update_datetime' },
      sortByOptions: [
        { text: this.$i18n.t('ID'), value: 'id' },
        { text: this.$i18n.t('Name'), value: 'name' },
        { text: this.$i18n.t('Date'), value: 'update_datetime' },
      ],
      sortDesc: { text: this.$i18n.t('Descending'), value: 'DESC' },
      sortDescOptions: [
        { text: this.$i18n.t('Ascending'), value: 'ASC' },
        { text: this.$i18n.t('Descending'), value: 'DESC' },
      ],
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      mediaTotal: null,
      products: [
        {
          id: 1,
          name: 'VicTsing Wireless Mouse,',
          slug: 'vic-tsing-wireless-mouse-1',
          description:
        'After thousands of samples of palm data, we designed this ergonomic mouse. The laptop mouse has a streamlined arc and thumb rest to help reduce the stress caused by prolonged use of the laptop mouse.',
          brand: 'VicTsing',
          price: 10.99,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/pages/eCommerce/27.png'),
          hasFreeShipping: true,
          rating: 3,
        },
      ],
      mediaLightBox: [{}],
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    filters: {
      handler() {
        this.getListMedia()
      },
      deep: true,
    },
    sortBy: {
      handler() {
        this.getListMedia()
      },
      deep: true,
    },
    sortDesc: {
      handler() {
        this.getListMedia()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDefaultMedia()
    const filterList = JSON.parse(localStorage.getItem('filterMedia'))
    if (filterList !== null) {
      this.filters.q = (filterList.q !== null) ? filterList.q : ''
      this.filters.perPage = (filterList.perPage !== null) ? filterList.perPage : ''
      // this.filters.page = (filterList.page !== null) ? filterList.page : ''
      this.sortBy.value = (filterList.sortBy !== null) ? filterList.sortBy : ''
      this.sortDesc.value = (filterList.sortDesc !== null) ? filterList.sortDesc : ''
    }
    this.getListMedia()
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    getDefaultMedia() {
      this.showOverlay = true
      store
        .dispatch('media-store/getDefaultMedia', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.mediaData = this.defaultData
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    getListMedia() {
      const sortByValue = this.sortBy.value
      let sortDescValue = false
      if (this.sortDesc.value === 'ASC') {
        sortDescValue = false
      } else {
        sortDescValue = true
      }
      const params = {
        q: this.filters.q,
        perPage: this.filters.perPage,
        page: this.filters.page,
        sortBy: sortByValue,
        sortDesc: sortDescValue,
        language: this.$i18n.locale,
        lineOaId: this.lineOaId,
      }
      store
        .dispatch('media-store/listMedia', params)
        .then(response => {
          // const okMsg = this.$i18n.t('Process on successfully')
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: okMsg,
          //     icon: 'CheckCircleIcon',
          //     variant: 'success',
          //   },
          // },
          // {
          //   position: 'bottom-right',
          // })
          const { list, total } = response.data
          this.mediaList = list
          this.mediaTotal = total
          localStorage.setItem('filterMedia', JSON.stringify(params))
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    addMediaForm() {
      this.showOverlay = true
      store
        .dispatch('media-store/getDefaultMedia', {
          language: this.$i18n.locale,
          lineOaId: this.lineOaId,
        })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.mediaData = response.data
          this.mediaData.type = 'image'
          this.mediaData.image_data = ''
          this.mediaData.image_url = ''
          this.mediaData.image_file = null
          this.typeMediaForm = 'Add'
          this.shallShowMediaFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editMediaForm(data) {
      this.showOverlay = true
      store
        .dispatch('media-store/getMedia', { language: this.$i18n.locale, id: data.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.mediaData = response.data
          this.mediaData.image_data = ''
          this.mediaData.image_url = ''
          this.mediaData.image_file = null
          if (this.mediaData.type === 'image') {
            this.mediaData.image_url = this.mediaData.media_url
          }
          this.mediaData.media_type = this.defaultData.media_type
          this.typeMediaForm = 'Edit'
          this.shallShowMediaFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteMediaForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('media-store/deleteMedia', { id: data.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getListMedia()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateMediaForm() {
      this.getListMedia()
    },
    discardMediaForm() {
    },
    showPopupImage(data) {
      const mediaArray = []
      let indexArray = 0
      let indexCnt = 0
      this.mediaList.forEach(list => {
        if (list.type === 'image') {
          if (list.id === data.id) {
            indexArray = indexCnt
          }
          const md = {
            src: list.media_url,
            caption: list.name,
          }
          mediaArray.push(md)
          indexCnt += 1
        }
      })
      this.mediaLightBox = mediaArray
      this.$refs.refImageLightBox.showImage(indexArray)
    },
    copyImageURL(data) {
      const copyText = (data?.media_url) || ''
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    copyImagemapURL(data) {
      const copyText = (data?.imagemap_url) || ''
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    copyVideoURL(data) {
      const copyText = (data?.media_url) || ''
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    playVideo() {
      // console.log('play video')
    },
  },
  setup() {
    if (!store.hasModule('media-store')) store.registerModule('media-store', mediaStoreModule)
    onUnmounted(() => {
      if (store.hasModule('media-store')) store.unregisterModule('media-store')
    })

    return {}
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/lineapi/media.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
