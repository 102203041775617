import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    listMedia(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/list_media', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_media', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/delete_media', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_media', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/upload_image_media/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadVideoMedia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/upload_video_media/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
