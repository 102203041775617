<template>
  <b-modal
    id="form-media"
    :visible="shallShowMediaFormModal"
    :title="`${$t('Form')} ${$t('Media')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-media-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeMediaForm)} ${$t('Media')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-media-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeMediaForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeMediaForm === 'Add' || typeMediaForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: Name -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeMediaForm === 'Add' || typeMediaForm === 'Edit')"
                      :label="$t('Name')"
                      label-for="media-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="media-name"
                          v-model="mediaData.name"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Name')"
                      label-for="media-name"
                    >
                      <span class="form-info-box">{{ mediaData.name }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Media Type -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeMediaForm === 'Add' || typeMediaForm === 'Edit')"
                      :label="$t('Media Type')"
                      label-for="media-type"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Media Type')"
                        rules="required"
                      >
                        <b-form-radio
                          v-for="(mediaType, index) in mediaData.media_type"
                          :key="index"
                          v-model="mediaData.type"
                          name="media-type"
                          :value="mediaType.value"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t(mediaType.name) }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Type')"
                      label-for="media-type"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          variant="light-info"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(mediaData.type)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row
                  v-if="mediaData.type === 'image'"
                >

                  <!-- Field: Image -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeMediaForm === 'Add' || typeMediaForm === 'Edit')"
                      :label="$t('Image')"
                      label-for="media-image"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Image')"
                        :rules="(typeMediaForm === 'Add') ? 'required' : ''"
                      >
                        <div class="file-upload-form">
                          <b-form-file
                            ref="refImageFile"
                            v-model="mediaData.image_file"
                            accept="image/*"
                            :placeholder="`${$t('Select Image')}...`"
                            no-drop
                            :browse-text="$t('Browse')"
                            @change="previewImage"
                          />
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </div>
                        <div style="padding-top: 10px;">
                          <span style="color: #999999; font-size: 12px;">
                            รูปแบบไฟล์: JPG, JPEG, PNG<br>
                            ขนาดไฟล์: ไม่เกิน {{ limitImageSize }} MB<br>
                          </span>
                        </div>
                        <div
                          v-if="mediaData.image_url !== ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="mediaData.image_url"
                            style="height: 120px;"
                          >
                        </div>
                        <div
                          v-if="mediaData.image_data != ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="mediaData.image_data"
                            style="height: 120px;"
                          >
                        </div>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Image')"
                      label-for="media-image"
                    >
                      <span class="form-info-box">{{ mediaData.image }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row
                  v-if="mediaData.type === 'video'"
                >

                  <!-- Field: Video -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeMediaForm === 'Add' || typeMediaForm === 'Edit')"
                      :label="$t('Video')"
                      label-for="media-video"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Video')"
                        :rules="(typeMediaForm === 'Add') ? 'required' : ''"
                      >
                        <div class="file-upload-form">
                          <b-form-file
                            ref="refVideoFile"
                            v-model="mediaData.video_file"
                            accept="video/*"
                            :placeholder="`${$t('Select Video')}...`"
                            no-drop
                            :browse-text="$t('Browse')"
                            @change="previewVideo"
                          />
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </div>
                        <div style="padding-top: 10px;">
                          <span style="color: #999999; font-size: 12px;">
                            รูปแบบไฟล์: MP4, MPEG4<br>
                            ขนาดไฟล์: ไม่เกิน {{ limitVideoSize }} MB<br>
                          </span>
                        </div>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Video')"
                      label-for="media-video"
                    >
                      <span class="form-info-box">{{ mediaData.video }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BBadge,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BBadge,
    BFormFile,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowMediaFormModal',
    event: 'update:shall-show-media-form-modal',
  },
  props: {
    shallShowMediaFormModal: {
      type: Boolean,
      required: true,
    },
    typeMediaForm: {
      type: String,
      default: 'Add',
    },
    mediaData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      limitImageSize: 5,
      limitVideoSize: 10,
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeMediaForm === 'Add') {
        delete this.mediaData.image_data
        delete this.mediaData.image_url
        this.mediaData.lineOaId = this.lineOaId
        store.dispatch('media-store/addMedia', this.mediaData)
          .then(response => {
            if (this.mediaData.image_file !== null && this.mediaData.type === 'image') {
              this.saveImage(response.data.id)
            } else if (this.mediaData.video_file !== null && this.mediaData.type === 'video') {
              this.saveVideo(response.data.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.$emit('media-form-update')
              this.$emit('update:shall-show-media-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMediaForm === 'Edit') {
        delete this.mediaData.image_data
        delete this.mediaData.image_url
        store.dispatch('media-store/editMedia', { id: this.mediaData.id, data: this.mediaData })
          .then(() => {
            if (this.mediaData.image_file !== null && this.mediaData.type === 'image') {
              this.updateImage(this.mediaData.id)
            } else if (this.mediaData.video_file !== null && this.mediaData.type === 'video') {
              this.updateVideo(this.mediaData.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('media-form-update')
              this.$emit('update:shall-show-media-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeMediaForm === 'Delete') {
        store.dispatch('media-store/deleteMedia', {
          id: this.mediaData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('media-form-update')
            this.$emit('update:shall-show-media-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    saveImage(id) {
      const formData = new FormData()
      formData.append('file', this.mediaData.image_file)

      store.dispatch('media-store/uploadImageMedia', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.mediaData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Added'),
            text: this.$i18n.t('Your data has been added'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('media-form-update')
          this.$emit('update:shall-show-media-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    updateImage(id) {
      const formData = new FormData()
      formData.append('file', this.mediaData.image_file)

      store.dispatch('media-store/uploadImageMedia', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.mediaData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Updated'),
            text: this.$i18n.t('Your data has been updated'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('media-form-update')
          this.$emit('update:shall-show-media-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    saveVideo(id) {
      const formData = new FormData()
      formData.append('file', this.mediaData.video_file)

      store.dispatch('media-store/uploadVideoMedia', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.mediaData.video_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Added'),
            text: this.$i18n.t('Your data has been added'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('media-form-update')
          this.$emit('update:shall-show-media-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    updateVideo(id) {
      const formData = new FormData()
      formData.append('file', this.mediaData.video_file)

      store.dispatch('media-store/uploadVideoMedia', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.mediaData.video_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Updated'),
            text: this.$i18n.t('Your data has been updated'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('media-form-update')
          this.$emit('update:shall-show-media-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-media-form')
      this.$emit('update:shall-show-media-form-modal', false)
    },
    previewImage(event) {
      if (event.target.files.length > 0) {
        this.mediaData.image_data = null
        this.mediaData.size = 0
        const file = event.target.files[0]
        const fileSize = (file.size / 1024 / 1024).toFixed(2)
        const fileSizeByte = file.size
        if (fileSize <= this.limitImageSize) {
          this.mediaData.size = fileSizeByte
          const input = event.target
          if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.mediaData.image_data = e.target.result
              const image = new Image()
              image.src = e.target.result

              image.onload = () => {
                const { width } = image
                const { height } = image
                this.mediaData.width = (width) || 0
                this.mediaData.height = (height) || 0
              }
            }
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$refs.refImageFile.reset()
          this.mediaData.image_data = null
          this.mediaData.size = 0
          const errorMsg = `${this.$i18n.t('File size exceeds')} ${this.limitImageSize} MB`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        }
      } else {
        // please select
        this.$refs.refImageFile.reset()
        this.mediaData.image_data = null
        this.mediaData.size = 0
        const errorMsg = this.$i18n.t('Please select file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    previewVideo(event) {
      if (event.target.files.length > 0) {
        this.mediaData.size = 0
        const file = event.target.files[0]
        const fileSize = (file.size / 1024 / 1024).toFixed(2)
        const fileSizeByte = file.size
        if (fileSize <= this.limitVideoSize) {
          this.mediaData.size = fileSizeByte
          const input = event.target
          if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              const video = document.createElement('video')
              video.preload = 'metadata'

              video.onloadedmetadata = () => {
                const { duration } = video
                this.mediaData.duration = (duration) || 0
                const { videoWidth } = video
                this.mediaData.width = (videoWidth) || 0
                const { videoHeight } = video
                this.mediaData.height = (videoHeight) || 0
              }
              video.src = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          }
        } else {
          this.$refs.refVideoFile.reset()
          this.mediaData.size = 0
          const errorMsg = `${this.$i18n.t('File size exceeds')} ${this.limitVideoSize} MB`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          })
        }
      } else {
        // please select
        this.$refs.refVideoFile.reset()
        this.mediaData.size = 0
        const errorMsg = this.$i18n.t('Please select file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
